
.root {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.centerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.list {
    margin-top: 3.5vw;
    width: 100vw;
}

.logo {
    width: 56vw;
    height: 56vw;
    margin-top: 2rem;
    object-fit: cover;
}

.title {
    font-size: 5vw;
    color: black;
    margin-top: 2vw;
    font-weight: 500;
}

.stepItemDone {
    color: #535F6A;
}

.stepItemCurrent {
    color: black;
}

.stepItemNotYet {
    color: #D3D9DF;
}

.stepItemDone, .stepItemCurrent, .stepItemNotYet {

    padding: 3.5vw 5vw;
    display: flex;
    flex-direction: row;
    max-lines: 1;
    font-size: 4vw;
    font-weight: 400;
    align-items: center;
}

.actionTextDone {
    font-size: 4vw;
    font-weight: 400;
    color: #06C177;
}

.actionTextCurrent {
    font-size: 4vw;
    font-weight: 500;
    color: #06C177;
}

.actionTextNotYet {
    font-size: 4vw;
    font-weight: 400;
    color: #D3D9DF;
}

.image {
    width: 4vw;
    height: 4vw;
    margin-left: 4vw;
}
