.root {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header {
    width: 100%;
    height: 3vw;
    background-color: #F7F8FC;
}

.content {
    padding: 4vw;
    display: flex;
    flex-direction: column;
}

.idImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 92vw;
}

.idImageBox {
    width: 44vw;
    height: 34vw;

}

.fileInput {
    width: 44vw;
    height: 34vw;
    background: transparent;
    position: absolute;
    margin-left: -44vw;
    border: none;
    opacity: 0;
}


.idImage {
    width: 44vw;
    height: 34vw;
    object-fit: cover;
}

.titleName {

}

.titleName, .titleNumber, .titleAddress {
    font-size: 4vw;
    margin-top: 5vw;
    font-weight: 400;
    color: #000000;
}

.input {
    background: #F6F6F6;
    border-radius: 1vw;
    padding: 3vw;
    width: 86vw;
    margin-top: 3vw;
    font-size: 4vw;
    min-height: 4vw;
}

.divider {
    margin-top: 5vw;
    width: 94vw;
    height: 1px;
    background: rgba(117, 130, 141, 0.06);
}

.uploadingContainer {
    width: 100vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

