
.root {
    background-color: #F7F8FC;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
}

.search::placeholder {
    color: white;
}

.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog {
    width: 100vw;
    height: 100vh;
    background-color: #00000099;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    display: flex;
}

.dialogHeader {
    width: 42vw;
    height: 44vw;
    margin-top: -22vw;
    margin-left: 21vw;
    margin-right: 21vw;

}

.dialogTitle {
    width: 100%;
    text-align: center;
    font-size: 6vw;
    font-weight: 600;
    color: #000000;
}

.dialogContent {
    width: 100%;
    text-align: center;
    font-size: 4vw;
    font-weight: 400;
    color: #535F6A;
    margin-top: 2vw;
}

.content {
    margin-top: 5vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-left: 4vw;
    padding-top: 4vw;
    padding-bottom: 5vw;
}

.title {

    font-size: 4vw;
    font-weight: 400;
    color: #000000;

}

.input {
    background-color: transparent;
    border: none;
    text-align: end;
    font-size: 4vw;
}

.row {
    display: flex;
    flex-direction: row;
    padding-right: 5vw;
    width: 90vw;
}

.divider {
    width: 95vw;
    height: 1px;
    background-color: rgba(117, 130, 141, 0.06);
}


.dialogContainer {
    width: 83vw;
    height: 75vw;
    background: linear-gradient(197deg, #DDFFF2 0%, #FFFFFF 100%);
    border-radius: 4vw;

}

.dialogButton {
    width: 64vw;
    height: 12vw;
    margin-top: 8vw;
    margin-left: 10vw;
    margin-right: 10vw;
}
