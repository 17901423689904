.root {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image {
    width: 80%;
    margin-top: 17vw;
}

.button {
    width: 90%;
}
