body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: 16px;
}

.MuiButton-contained {
    background: linear-gradient(356deg, #1ED193 0%, #06C177 100%) !important;
    font-size: 5vw !important;
    height: 13vw !important;
}
