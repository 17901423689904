
.lineBar {
    padding: 16px;
    color: #000000;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    background: #f7f8fc;
}

.tabLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 32px;


}

.tag {
    width: 30vw;
    height: 4vh;
    color: #535f6a;
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    background: #f8f8f8;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectTag {
    background: #23BE7F !important;
    color: #FFFFFF !important;
}

