.aboutMe {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    /*justify-content: center;*/
    flex-direction: column;
    overflow: auto;
}


.picLogo {
    width: 50%
}

.picSlogan {
    width: 50%
}
