
.phoneInput {
    background: #F6F6F6;
    border-radius: 1vw;
    padding: 3vw;
    width: 86vw;
    margin-top: 3vw;
    font-size: 4vw;
    border: none;
}

.codeContainer {
    width: 92vw;
    display: flex;
    flex-direction: row;
    margin-top: 3vw;
}

.codeInput {
    background: #F6F6F6;
    border-radius: 1vw;
    padding: 3vw;
    font-size: 4vw;
    flex: 1;
    border: none;
}

.getCodeButton {
    background: #06C177;
    border-radius: 1vw;
    padding: 3vw;
    color: white;
    text-align: center;
    margin-left: 3vw;
}

/*去掉输入框输入时的边框*/
input:focus {
    outline: none;
}
