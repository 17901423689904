.articleAgreement {
    height: 100vh;
    width: 100%;
    background-color: #F7F8FC;
    display: flex;
    align-items: center;
    flex-direction: column;


}

.item {
    margin-top: 32px;
    width: 80vw;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    font-size: 20px;
    justify-content: space-between;
    color: #000000;
}

.itemText {
    font-size: 20px;
}

.image {
    width: 16px;
}
