.settleHelp {
    background: #FFF7F8FC;


}

.content {
    margin-bottom: 20px;
    background: #FFFFFFFF;
    padding: 16px;
}

.headerTitle {
    font-size: 20px;
    margin: 0px auto;
    font-weight: 500;
    color: #000000;
}


.title {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
}

.textWord {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 400;
    color: #374149;
    line-height: 18px;
}
