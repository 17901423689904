.root {
    background-color: #F7F8FC;
    display: flex;
    flex-direction: column;
    height: 92vh;
    width: 92vw;
    padding: 4vw;
}

.div {
    background-color: #F7F8FC;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header {
    font-size: 6vw;
    font-weight: bold;
    margin-top: 16vw;

}

.input {
    width: 92vw;
    font-size: 5vw;
}

.hint {
    color: red;
    width: 92vw;
    margin-top: 4vw;
}


.error {
    color: red;
    text-align: center;
    width: 84vw;
    margin-top: 4vw;
    padding: 4vw;
    background-color: #ccc;
}

.dialog {
    width: 100vw;
    height: 100vh;
    background-color: #00000099;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    display: flex;
}

.dialogContainer {
    width: 83vw;
    height: 75vw;
    background: linear-gradient(197deg, #DDFFF2 0%, #FFFFFF 100%);
    border-radius: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.dialogButton {
    width: 64vw;
    height: 12vw;
    margin-top: 8vw;
    margin-left: 10vw;
    margin-right: 10vw;
}

.dialogHeader {
    width: 42vw;
    height: 44vw;
    margin-top: -22vw;
    margin-left: 21vw;
    margin-right: 21vw;

}

.dialogTitle {
    width: 100%;
    text-align: center;
    font-size: 6vw;
    font-weight: 600;
    color: #000000;
}

.dialogContent {
    width: 100%;
    text-align: center;
    font-size: 4vw;
    font-weight: 400;
    color: #535F6A;
    margin-top: 2vw;
}
