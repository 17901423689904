.button {
    width: 27vw;
    height: 12vw;
    font-size: 3vw;
    font-weight: 400;

}


.action {
    background: #06C177;
    border-radius: 1px;
    padding: 3vw;
    color: white;
    text-align: center;
    margin-left: 3vw;
    border: none;
}

.action:disabled {
    background: #eeeeee;
    color:grey;
}
