.title {
    font-size: 5vw;
    font-weight: 500;
    color: #000000;
    margin-top: 11vw;
}

.subTitle {
    font-size: 4vw;
    font-weight: 400;
    color: #535F6A;
    margin-top: 2vw;
}

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4vw;
    height: calc(100vh - 8vw);
}

.logo {
    width: 56vw;
    margin-top: 2vw;
}

.titlePhone {
    font-size: 4vw;
    font-weight: 400;
    color: #000000;
    width: 100%;
}

.phone {
    background: #F6F6F6;
    border-radius: 1vw;
    padding: 3vw;
    width: 86vw;
    margin-top: 3vw;
    font-size: 4vw;
}

.codeContainer {
    width: 92vw;
    display: flex;
    flex-direction: row;
    margin-top: 3vw;
}

.code {
    background: #F6F6F6;
    border-radius: 1vw;
    padding: 3vw;
    font-size: 4vw;
    flex: 1;
    border: none;
}



.button {
    width: 100%;
    margin-bottom: 2vw;
}
