.certSuccess {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}

.banner {
    width: 50vw;
    height: 50vw;
}

.des {
    width: 50vw;
}

.title {
    margin-top: 4vh;
    margin-bottom: 4vh;
    font-size: 24px;
    font-weight: 500;
    color: #000000;
    line-height: 56px;
}

.btn {
    margin-top: 14vh;
    width: 90vw;
    height: 6vh;
    background: linear-gradient(356deg, #1ED193 0%, #06C177 100%);
    box-shadow: 4px 8px 16px 0px rgba(140, 237, 157, 0.5);
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 6vh;
    text-align: center;
}
